:root {
	--logo25em			: 2.5em;
	--lentaWidth 		: 0%;
	--lentaDisplay	: none;

	--logoX					:	1;

	--sha-size-lenta : 2px 2px 12px;
}

.Lenta {
	border-right: 1px solid var(--cl-med);
	/* box-shadow: var(--sha-size-lenta) var(--sha-color); */

	/* max-height: 100vh !important; */
	font-size: calc((0.5em + 2vw) * 0.8);

	overflow: hidden;

	display: var(--lentaDisplay);
	width: var(--lentaWidth);
	height: calc(100vh - var(--footer-height));

	/* height: calc(100vh - var(--footer-height)); */

	z-index: 100;
}

@media all and (max-width: 1200px) {
	/* в Main по этой же ширине "width: 100%;" в Schedule тоже */
	.Lenta {
		display: none;
	}
}

.h_Logo {
	padding: var(--logoPadding);

	height: var(--logo25em);
	min-height: var(--logo25em);
	max-height: var(--logo25em);

	border-bottom: 1px solid var(--cl-med);
	z-index: 111;
}

.h_Logo > img {
	height: var(--logo25em);
	max-height: var(--logo25em);
	
  display: block;
	margin: var(--logoMargin);

	width: var(--logoWidth);
	height: var(--logoHeight);

	/* border: 1px dashed silver; */
}

.ddd {
	position: relative;
	height: 100%;
	height: calc(100% - var(--logo25em));
}

.pic_cont_cont {
	position: relative;
	z-index: 0;
	
	/* border: 2px dashed red; */
	overflow: hidden;
	height: calc(100% - var(--logo25em));
	/* height: 100%; */
}

:root {
	--pic_cont_top : 0px;
}

.pic_top {
	z-index: 100;
	position: relative;
	/* position: absolute; */
	border-bottom: 1px solid white;
	box-shadow: var(--sha-size-lenta) var(--sha-color);
}

.pic_cont {
	position: absolute;
	overflow: hidden;
	top: var(--pic_cont_top);

	overflow-y: scroll;

	width: calc(100% + 17px);
	height: 100%;

	 /* Increase/decrease this value for cross-browser compatibility */
	/* padding-right: 17px; */
  box-sizing: content-box; /* So the width will be 100% + 17px */

	/* top: 2.5em;
	width: 20%; */
}

.pic {
	border-bottom: 1px solid transparent;
}

.pic > img, .pic_top > img, .pic_curr > img {
	background: transparent;
	width: calc(100% - 0px);
	cursor: pointer;
	opacity: .75;
	filter: grayscale(100%);
	transition: filter .3s linear, opacity .3s linear;
}

.pic_top > img {
	opacity: 1;
	filter: grayscale(0%);
}

.pic_curr > img {
	opacity: 1;
	filter: grayscale(0%);
	/* margin-left: 20px; */
	/* z-index: 100; */
	/* position: fixed; */
	/* width: 20%; */
	/* border: 14px solid var(--cl-med-dark); */
	/* box-shadow: 0px 0px 10px 10px black; */
}

.pic_full {
	border-radius: 3px;
	z-index: 999;
	/* position: relative; */
	width: auto;
	max-width: 55%;
	max-height: 100%;
	/* max-height: 19%; */
	/* border: 4px solid var(--cl-med); */

	/* border: 1px solid var(--cl-med); */
	
	/* background-color: var(--cl-header-back); */
	background-color: var(--cl-med);

	box-shadow: var(--sha-size) var(--sha-color);
	/* padding: 2em 0 0em; */
	/* margin: auto; */
	display: block;
	position: relative;
}

.pic_full_name {
	/* font-size: calc(var(--fs)*3/3) !important; */
	/* line-height: 1.0em !important; */
	/* line-height: 1.5em !important; */
	font-size: 1em !important;
	text-shadow: 0px 0px 2px var(--cl-med-dark);
	height: 2em;
	font-weight: 500;

	color: var(--cl-light);

	/* position: absolute;
	left: 0.5em;
	top: .25em;
	max-width: 90%; */
	/* overflow: hidden; */
	white-space: wrap;
	padding: 0.2em 0.4em;
}

.pic_full > img {
	object-fit: cover;
	width: 100%;
}