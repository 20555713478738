:root {
  --cl-fone					: #fff;

	--cl-header-color	: #fff;
	--cl-header-back	: #666;
  
  --cl-light				: #fff;
	--cl-med-light		: #ddd;
	--cl-med					: #bbb;
	--cl-med-dark			: #aaa;	/* для todayN */
	--cl-dark					: #666;
	
	--cl-btn-back			: #fff;
	--cl-btn-color		: #888;

	--captionX				: 1em;
}

body {
	background-color: var(--cl-fone) !important;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	/* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
