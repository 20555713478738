:root {
	--sha-size:		1em 1em 1em;
	--sha-color:	rgba(0,0,0,.5);

	--footer-height: 3.3vmax;

	--fs:	30px;
	--hh:	80px;
	--lw:	80px;

	--captionX:		1em;

	/* --fs-large:		48px;
	--hh-large:		120px;
	--lw-large:		120px; */
	--fs-large:		30px;
	--hh-large:		80px;
	--lw-large:		80px;

	/* --fs-normal:	38px;
	--hh-normal:	100px;
	--lw-normal:	100px; */
	--fs-normal:	30px;
	--hh-normal:	80px;
	--lw-normal:	80px;

	--fs-small:		30px;
	--hh-small:		80px;
	--lw-small:		80px;
}

@media all and (min-width: 1300px) {
	:root {
		--fs: var(--fs-large);
		--hh:	var(--hh-large);
		--lw:	var(--lw-large);
		/* --fs: calc(var(--fs-large) - var(--fs-NRM) - var(--fs-SML));
		--hh:	calc(var(--hh-large) - var(--hh-NRM) - var(--hh-SML));
		--lw:	calc(var(--lw-large) - var(--lw-NRM) - var(--lw-SML)); */
	}
}

@media all and (max-width: 1300px) {
	:root {
		--fs: var(--fs-normal);
		--hh:	var(--hh-normal);
		--lw:	var(--lw-normal);
	}
}

@media all and (max-width: 1100px) {
	:root {
		--fs: var(--fs-small);
		--hh:	var(--hh-small);
		--lw:	var(--lw-small);
	}
}


/* @media all and (max-width: 700px) {
	.fade {
		background-color: rgba(0, 0, 0, 0.0) !important;
	}
} */

.modalWindow {
	box-shadow: var(--sha-size) var(--sha-color);
	max-height: 80vh;

	/* font-size: 1.75em; */
	font-size: calc(10px + 1vw);
}

.modalWide {
	min-width: 50vw;
}

.modalHeader {
	/* background-color: var(--cl-header-back);
	color: var(--cl-header-color); */
	background-color: var(--cl-med);
	color: var(--cl-light);
}

.modalTitle {
	/* font-size: calc(var(--fs)*3/3) !important; */
	line-height: 0.9em !important;
	font-size: 1.4em !important;
	font-weight: 500;

	/* margin-left: 0.25em; */
}

.modalBody {
	/* margin: calc(var(--fs)/5) calc(var(--fs)/3) calc(var(--fs)/5); */
	margin: 0.5vmax 0.75vmax 0.5vmax;
	line-height: 1.25;
	overflow-y: auto;
	/* padding-top: 40px !important; */
}

.modalBody img {
	max-width: 100%;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.Footer {
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 100%;

	min-height: var(--footer-height);
	font-size: max(2vmax, 16px);
	/* font-size: calc(var(--fs) * 2/3); */

	background-color: var(--cl-header-back);
	color: var(--cl-header-color);

	text-align: left;
	padding: 0px;
	/* overflow: hidden; */
	white-space: nowrap;

	/* border: 1px dashed red; */
	border-top: 1px solid var(--cl-dark);
	cursor: pointer;
	/* чтобы (иногда) не перекрывался Schedule_T -> .table_divider (33) */
	z-index: 66;
}

cl_red {
	color : #f00;
}

.cl_med {
	color : var(--cl-med);
}

.f_eventDate_cont {
	display: flow-root;
	position: relative;
	width: 100%;
	margin-bottom: 1em;
}

.f_eventDate {
	/* text-align: right; */
	float: left;
	/* font-weight: 500; */
	border-bottom: 1px solid var(--cl-med);
}

.f_Caption {
	/* border: 1px dashed yellow; */
	/* margin-left: calc(var(--fs) * 1.2); */
	/* line-height: 1.5; */

	/* text-indent: -600px; */
	overflow: hidden;
}

/* .Footer:hover {
	color: white;
} */

.f_Icon, .f_Icon_Msg {
	width: calc(calc(var(--hh) - 15px) / 2);
	height: calc(calc(var(--hh) - 15px) / 2);
	float: left;
	/* background-color: lime; */
	
	display:table-cell; 
	vertical-align:middle;
	text-align:center;
	display: flex;
}

.f_Icon_Msg {
	margin-right: 10px;	
}

.f_Icon > img {
	width: calc((var(--hh) - 20px) *2/5);
	height: calc((var(--hh) - 20px) *2/5);
	/* width: 32px;
	height: 32px; */
	/* border: 1px dashed silver; */
	margin: auto;
}

.visible {
  visibility: visible;
  filter: grayscale(0);
	opacity: 1;
  /* transition: filter 0.1s linear, opacity 0.1s linear; */
}

.hid {
	visibility: hidden;
}

/* .hidden {
  visibility: visible;
  filter: grayscale(100%);
	opacity: 0.5;
  transition: filter 1.5s linear, opacity 1.5s linear;
} */

/* .visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}
 */
.colon_hide {
	color: var(--cl-dark);
}

.Header_filled {
	background-color: var(--cl-header-back) !important;
	border-bottom: 1px solid var(--cl-med);
	text-shadow: none;
}

:root {
	/* в prepareUI() выдёргиваются из cls['fone'] */
	--sh-r : 255;
	--sh-g : 255;
	--sh-b : 255;
}

.Header {
	/* background-color: var(--cl-header-back); */
	border-bottom: 1px solid var(--cl-med);
	text-shadow: 0px 0px 10px  var(--cl-header-back);

	/* box-shadow: 0px 3em 15px 5px rgba(255,255,255,.65); */
	/* box-shadow: 0px 2.3em 15px 1px rgba(255,255,255,.5); */
	box-shadow: 0px 2.3em 15px 1px rgba(var(--sh-r), var(--sh-g), var(--sh-b), 0.5);
	
	cursor: pointer;
	width: 100%;
	position: relative;

	/* min-height: var(--hh); */
	/* max-height: var(--hh); */


	color: var(--cl-header-color);

	text-align: center;
	
	/* padding: .5vw !important; */
	padding: .2em !important;


	 /* + 0.1em + 0.3vw); */
	/* overflow: hidden; */
	white-space: nowrap;

	/* font-size: var(--fs); */
	font-size: calc((0.5em + 2vw) * 0.8);
	height: calc((2.5em));
	/* max-height: calc(8vw); */
	/* min-height: 2.2em;
	max-height: 2.2em; */

	/* z-index: 10; */
	/* border: 1px dashed silver; */

	/* display: flex; */
	/* justify-content: space-evenly; */
	/* border: 1px dashed red; */
}

@media all and (max-width: 520px) {
	.Header {
		background-color: rgba(0,0,0,0.15);
		box-shadow: none;
	}
	/* .Header {
		box-shadow: none;	
		border-bottom: none;
	} */
}

div > a {
	color: unset !important;
}

/* a {
	color: unset !important;
} */

.nowrap {
	white-space: nowrap !important;
}

.h_UZName, .h_Time, .h_Date, .h_Title {
	float: left;
	/* margin-left: 20px; */

	height: 100%;
	/* border: 1px dashed silver; */

	text-align: left;
	font-weight: 500;
	line-height: 1.1;
}

.h_Title {
	/* Обязательно auto, иначе всё выровняется в Header, т.к. он flex */
	/* margin: auto; */
	float: left;
	
	/* font-size: calc(0.75em + 2vw); */
	font-size: 1.5em;
	line-height: 1.2;

	padding: 0;
	/* margin-top: 0.2em; */
	margin: 0;
	margin-left: 1vw;

	/* font-size: 1.3em; */

	/* text-align: right; */
	/* align-items: right; */
	/* border: 1px dashed blue; */
	/* position: relative; */

	/* чтобы I/II не переносилось */
	/* min-width: 7em; */
}

.h_Title > small {
	font-size: 0.5em;
	font-weight: normal;
}

.h_UZName {
	margin-left: 0.1em;
	margin-top: 0;

	line-height: 1;
	/* margin-bottom: 0.25em; */
	/* color: var(--cl-med); */
	/* max-width: 580px; */
	/* white-space: pre-wrap; */
	/* margin-top: -5px; */

	font-size: var(--captionX);
	/* font-size: 1.5em; */
	
	/* border: 1px dashed red; */
}

/* .h_UZName > small {
	font-size: 0.5em;
	font-weight: normal;
} */

.h_Time {
	float: right;
	font-size: 2em;
	vertical-align: middle;
	/* vertical-align: top; */
	line-height: 0.9;
	/* border: 1px dashed red; */
}

.date_cont {
	/* border-left: 4px solid var(--cl-med); */
	float: right;

	margin-left: 0.3em;
	margin-top: -10px;
	padding-top: 10px;

	/* calc(var(--hh) * 0.9); */
	min-height: var(--he);
	max-height: var(--he);
}

:root {
	--he : 2.5em;
}

.weekN_cont {
	float: left;
	background-color: var(--cl-med);
	color: white;

	margin-top: -10px;
	padding-top: 10px;
	/* min-width: 1.75em; */
	/* display: flex; */

	display: table-cell;

	height: var(--he);
	/* border-left: 1px solid black; */
	/* position: relative; */
	vertical-align: middle;
}

.h_WeekN {
	display: inline-block;
	vertical-align: middle;

	text-align: center;
	padding: 0 1vw;
	text-shadow: none;

	font-size: 2em;
	line-height: 1;
	margin-top: -5px;
}

.h_WeekN_empty {
	padding: 0 2px;
}

.h_Date {
	font-size: 0.75em;
	line-height: 1.2;
	margin-top: 0.1em;
	vertical-align: middle;
	position: relative;

	margin-left: 0.5em;
	/* margin-right: 0.5vw; */
	/* border: 1px dashed red; */
}


@media all and (max-width: 375px) {
	:root {
		--he : 2.7em;
	}
	.h_UZName {
		line-height: 1.1;
	}
	.h_WeekN {
		line-height: 1.3;
	}
	.h_Time {
		line-height: 1.0;
	}
}

@media all and (max-width: 720px) {
	.h_Date {
		display: none;
	}
	.h_WeekN_empty {
		display: none;
	}
}

@media all and (max-width: 560px) {
	/* .date_cont {
		font-size: 0.9em;
	}
	.h_WeekN {
		font-size: 1.75em;
	}
	.h_Time {
		font-size: 1.75em;
	} */
}

@media all and (max-width: 359px) {
	.h_Time {
		font-size: 1.5em;
		padding-top: 0.05em;
		line-height: 1.1;
	}
	.date_cont {
		font-size: 0.9em;
	}
}

@media all and (max-width: 310px) {
	.date_cont {display: none;}
}

@media all and (max-width: 270px) {
	.h_Time {display: none;}
}

@media (hover:none), (hover:on-demand) { 
  /* custom css for "touch targets" */
}

