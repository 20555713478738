.OdminPanel {
	background-color: var(--cl-dark);
	color: var(--cl-light);
	font-size: min(0.75em, 14vmax);
	line-height: 1.25;
	min-height: 1.4em;
	position: relative;
	padding-left: 0.25em;

	width: 100%;

	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
}

.op_left {
	display: inline;
	float: left;
	margin-left: 1.25em;
}
.op_left:first-child {
	margin-left: 0;
}

.op_right {
	float: right;
	margin-right: 0.25em;
}
.op_right:last-child {
	margin-right: 0;
}

.op_button_cont {
	font-size: 0.75em;
	margin-top: 0.1em;
	/* margin-left: 1em; */
	/* background-color: white; */
	
	height: 1.6em;
	
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	/* margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}

.op_button {
	display: inline-block;
	border-radius: 2.5px;
	color: var(--cl-btn-color);
	background-color: var(--cl-light);
	margin-left: 0.5em;
	margin-top: 0.125em;
	padding: 0.125em 0.5em;
	height: calc(1.25em + 0.15em);
	line-height: 1;
	border: 1px solid var(--cl-med-dark);
	color: black;
	cursor: pointer;
}

.op_button:hover, .op_button.selected {
	color: black;
	background-color: white;
}

.op_button.selected {
	/* border: 1px solid var(--cl-med-dark); */

	animation-duration: 0.5s;
  animation-name: bombom;
  animation-iteration-count: infinite;
}

@keyframes bombom {
  from {
    color: white;
  }
	50% {
    color: black;
  }
  to {
    color: white;
		/* background-color: var(--cl-btn-back); */
  }
}

/* .op_button:hover {
	color: white;
	background-color: var(--cl-med);
} */
