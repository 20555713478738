.Main, .Main100 {
	/* border: 2px dashed green; */
  text-align: center;
  width: calc(100% - var(--lentaWidth));
  height: calc(100vh - var(--footer-height));
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	/* border: 2px dashed red; */
}

.Main100.login {
  width: 100%;
	height: 100vh;
}

/* .fone_main {
	background-image: url('../images/fone_main.png');
	background-position: bottom right;
	background-repeat: no-repeat;
	background-size: 100% auto;
}

.fone_main_2 {
	background-image: url('../images/fone_main.png'), url('../images/fone_select.png');
	background-position: bottom right, top;
	background-repeat: no-repeat, no-repeat;
	background-size: 100% auto, 100% auto;
}

.fone_others {
	background-image: url('../images/fone_select.png');
	background-position: top;
	background-repeat: no-repeat;
	background-size: var(--fone-width) auto;
}  */


:root {
	--fone-width: 100%;
	--fone-width-left: 30%;
	--fone-width-left-fix: 350px;
	--fone-width-right: 70%;
}

.img_fone_main {
	position: absolute;
	width: var(--fone-width);
	height: auto;
	bottom: 0px;
	filter: hue-rotate(var(--hue-rotate-fone));
	pointer-events: none;
}

.img_fone_main_left {
	position: absolute;
	width: max(var(--fone-width-left-fix), var(--fone-width-left));
	height: auto;
	bottom: 0px;
	left: 0px;
	/* filter: hue-rotate(var(--hue-rotate-fone)); */
	pointer-events: none;
}

.img_fone_main_right {
	position: absolute;
	width: var(--fone-width-right);
	height: auto;
	bottom: 0px;
	left: var(--fone-width-left);
	/* right: 0px; */
	filter: hue-rotate(var(--hue-rotate-fone));
	pointer-events: none;
}

.img_fone_bw {
	filter: 
		opacity(50%)
		saturate(0%);
}

.img_fone_header {
	position: absolute;
	width: var(--fone-width);
	height: auto;
	top: 0px;
	left: 0px;
	filter: hue-rotate(var(--hue-rotate-fone));
	pointer-events: none;
} 

/* @media all and (max-width: 600px) {
	:root {
		--fone-width: 125%;
		--fone-width-left: 40%;
		--fone-width-right: 85%;
	}
}

@media all and (max-width: 400px) {
	:root {
		--fone-width: 150%;
		--fone-width-left: 50%;
		--fone-width-right: 100%;
	}	
} */

.Main100 {
	width: 100%;
}

@media all and (max-width: 1200px) {
	/* в ленте по этой же ширине "display: none;" в Schedule тоже */
	.Main {
		width: 100%;
	}
}


:root {
	--nav-bottom :	0;
	--nav-display:	block;
	--nav-opacity:	50%;
	--nav-width:		70px;
}

.NavButton, .NavButtonRight {
	/* background-color: var(--cl-med); */
	color: white;
	background-color: var(--cl-med);

	position: absolute;
	/* left: calc(1vw + 2px); */
	right: 0;
	/* calc(2vw); */
	/* bottom: calc((var(--hh) - 15px) / 2 + 1vh + var(--nav-bottom)); */
	bottom: calc(var(--filter_mb) + var(--nav-bottom));
	width: 100px;

	/* height: 30px; */
	min-height: calc(var(--nav-width) - 2px);
	max-height: calc(var(--nav-width) - 2px);
	font-size: 1.25em;
	line-height: 1.6;
	min-width: var(--nav-width);
	max-width: var(--nav-width);
	 
	font-family: Wingdings;

	padding: 0;
	margin: 0;
	/* padding-top: 5px; */

	white-space: wrap;
	overflow: hidden;

	z-index: 10000;
	display: var(--nav-display);
	opacity: 0.5;

	transition: opacity .3s linear;
	margin-right: var(--FilterMargin) !important;
}

.LangButton_cont {
	/* background-color: lime; */
	color: var(--cl-light);
	font-size: min(1em, 3vmax);
	/* line-height: 1.125; */
	/* min-height: 3.25em; */
	position: relative;
	/* padding-left: 0.25em; */

	width: 100%;

	/* display: flex;
	flex-wrap: nowrap; */
	white-space: nowrap;
}

.LangButton {
	/* border: 2px dashed red; */
	/* top: calc((0.5em + 2.2vw) * 2); */
	/* display: inline-block; */
	font-family: unset;
	/* margin-top: 2.3em; */
	min-height: 1.6em;
	max-height: 1.6em;
	min-width: calc(3em);
	max-width: calc(3em);
	font-size: 1em;
	line-height: 1.3;
	/* opacity: var(--nav-opacity); */

	margin: 0.4em 0.4em 0 0 !important;

	float: right;
}

/* .LangButtonR {
	right: 80px;
}

.LangButtonRR {
	right: 160px;
}  */

.NavButtonRight, .NavButtonRightSingle {
	line-height: 1.8;
}

@media all and (max-width: 1000px) {
	/* 1000, а не 720, какраз когда появляется прокрутка у расписания */
	.img_fone_main_left {
		left: -50px;
	}

	:root {
		--nav-width:		50px;
	}

	.NavButton, .NavButtonRight {
		font-size:		unset;
		line-height:	unset;	
	}
	.NavButton {
		line-height: 1.4;
	}
	.NavButtonRightSingle {
		line-height: 1.6;
	}
}

@media all and (max-width: 720px) {
	.img_fone_main_left {
		left: 0px;
	}
	.img_fone_main_right {
		display: none;
	}
	.NavButton {
		margin-bottom: 0 !important; 
	}
}

@media all and (max-width: 400px) {
	.NavButton {
		min-width: 45px !important;
		max-width: 45px !important;
	}
}

.NavButtonRight {
	/* bottom: calc((var(--hh) - 15px) / 2 + 1vh + var(--nav-bottom) + 65px); */
	/* margin-right: var(--FilterMargin); */
	right: calc(var(--nav-width) + 10px);
	left: unset;
	display: block;
}

.NavButton:hover, .LangButton:hover, .LangButton.selected {
	opacity: 1;
}

.NavButton {
	display: block;
}

.menu_as_board {
	font-size: 3.5vmax;
	background: white;
	/* box-shadow: 10px 10px 10px rgba(0,0,0,0.25); */
	box-shadow: 10px 10px 5px rgba(0,0,0,0.25); 
							/* inset 55px 55px 55px 55px  rgba(0,0,0,0.1); */
	border: 0.1em solid var(--cl-dark);
	line-height: 1.2 !important;
	font-weight: 400 !important;
	font-family: 'Academy KZ';
	font-style: italic;
}

.menu_cont, .menu_cont_no_uz {
	/* background: white; */
	text-align: left;
	font-size: 4vmax;
	font-weight: var(--fw-bold);

	/* padding: 0.75em 1.5em 0.75em 1.5em; */
	padding: 0.45em .75em 0.25em .5em;

	/* margin: auto; 5vh */
	margin: 5vh auto auto 20vw;

	position: relative;
	line-height: 1.2 !important;
}

.menu_cont_no_uz {
	margin: 15vh auto auto auto;
}

.menu_cont_lower {
	margin: calc(20vh - 5vw) auto auto auto;
}

@media all and (max-width: 1400px) {
	.menu_cont{
		margin: calc(15vh - 5vw) auto auto 20vw; /* 5vh auto auto 25vw; */
	}
}

@media all and (max-width: 1200px) {
	.menu_cont{
		margin: calc(15vh - 5vw) auto auto auto; /* 5vh auto auto 25vw; */
		/* font-size: 5vmax; */
	}
}

@media all and (max-width: 1000px) {
	.menu_cont{
		margin: calc(15vh - 5vw) auto auto auto;
		font-size: 5vmax;
	}
}

@media all and (max-width: 800px) {
	.menu_cont{
		margin: 15vh auto auto auto;
	}
}

@media all and (max-width: 500px) {
	.menu_cont{
		/* margin: 20vh auto auto auto; */
		font-size: 4vmax;
	}
}

.menu_item {
	/* color: black; */
	color: var(--cl-dark);
	/* color: var(--cl-dark); */

	/* border-bottom: 1px solid var(--cl-med-light); */

	cursor: pointer;
	position: relative;
	margin-bottom: calc((5vh) / 3);
	/* line-height: 1.2; */
}

.menu_item:hover, .menu_item.gray:hover {
	color: var(--cl-med);
}

/* .menu_item.gray {
	color: var(--cl-light);
	font-weight: 400;
}

.menu_item:hover {
	color: var(--cl-light) !important;
} */

.menu_item:before {
	line-height: 1.0;
	/* color: var(--cl-light) !important; */
}

.menu_item > img {
	width: 3.5vmax;
	height: 3.5vmax;
	margin-right: 2vmax;
	margin-top: -1vmax; 
	border: none;

	/* filter:
        contrast(50%)
        invert(250%)
        sepia(100%)
        saturate(10000%)
				hue-rotate(var(--hue-rotate)); */
}

:root {
	--hue-rotate: 0deg;
}

.menu_item:hover > img, .menu_item.curr > img {
	filter: 
        contrast(50%)
        invert(100%)
        sepia(100%)
        saturate(10000%)
				hue-rotate(var(--hue-rotate));
}

.menu_divider {
	/* background: var(--cl-med); */
	display: block;
	/* height: 10px; */
	min-height: 0.5em;
}
