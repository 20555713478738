.Login {
	border: 1px solid var(--cl-med);
	background-color: var(--cl-light);
	/* box-shadow: var(--sha-size) var(--sha-color); */
	border-radius: 5px;
	margin: auto;
	margin-top: 15vh;
	position: relative;
	font-size: 0.75em;
}

@media all and (max-height: 800px) {
	.Login {
		margin: auto;
		margin-top: 15vh;
	}
}

.Login_Title {
	background-color: var(--cl-med-light);
	/* background-image: linear-gradient(var(--cl-light), white); */
	padding: 0.5em;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.Login_Body {
	/* background-color: var(--cl-med); */
	/* border: 1px solid var(--cl-med); */
	height: auto;
	padding: 1em;
}

.Login_Hint {
	color: gray;
	font-size: 0.65em;
	padding: 0.2em;
	text-align: left;
}

.MuiOutlinedInput-input, .Mui-focused {
	color: black !important;
}

.Login_Buttons {
	background-color: var(--cl-med-light);
	/* background-image: linear-gradient(white, var(--cl-light)); */
	width: 100%;
	align-content: center;
	padding: 0.2em;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.Button_Login {
	display: inline-block;
	max-width: unset;
	padding: 0 1ch;
}

.Button_Default {
	box-shadow: 0px 0px 0px 1.25px blue;
}

.login_msg_ok {color: green;}
.login_msg_fail {color: red;}

/* .Login_Button:hover {
	border-color: gray;
} */