:root {
	--subj-name-h : 2.5em;
	--col_width		: 8.2em;			/* для (lesson_hours 1) ставим 9.2 */

	--sched_fs		: 1.5vw;
	--table_fs		: 18px;
	--table_fs360	: 16px;
	--sched_mw360	:	1010px;

	--fw-500			: 400;
	--fw-bold			: 500;

	--scale				: 0.8;
}

.Schedule {
	text-align: left;

	/* border: 1px dashed red !important; */
	
	/* border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc; */

	font-size: calc(var(--scale) * var(--sched_fs));		/* это тут ваще пофиг. тут только table */

	/* font-size: calc(0.5em); */
	/* font-size: calc(2.5vmax); */

	/* margin: auto; */
	position: relative;

	margin-left: 1vw;
	width: calc(100% - 2vw);

	margin-top: 0px;
	margin-bottom: 10px;

	min-width: calc(var(--scale) * 800px);

	background-color: var(--cl-table-back);
	/* outline: 1px solid var(--cl-light); */
	
	/* min-height: 1000px; */
	/* display: flex; */
}

.sched_footer {
	/* border: 1px dashed red !important; */
	text-align: left;
	line-height: 1.2;
	font-size: calc(max(min(0.25em + 2vw, 1em), 0.6em));

	/* float: left; */
	margin-left: calc(max(1vw, 0.5em));
	/* margin-bottom: 10px; */
}

.week_hours {
	font-weight: 500;
}

/* .sched_footer_cont > :last-child {
	margin-bottom: 0.5em;
} */

table {
	width: 100%;
	font-size: calc(1em - (var(--lentaWidth) - 20%) * 1.25);

	/* border-bottom: 1px solid var(--cl-med); */

	/* outline: 1px solid var(--cl-med);
	outline-offset: -1px; */
	border-right: 1px solid var(--cl-med);
	border-bottom: 1px solid var(--cl-med);
	/* outline-offset: -1px; */
	
	/* margin-bottom: 20px; */
	/* margin-bottom: calc(var(--nav-bottom) + 1vh); */

	/* font-size: calc(1.4vmax); */
	/* border-spacing: 5px; */
}

.col_width {
	/* border: 1px dashed red !important; */
	/* для школ надо 9.2, у них нет типов предетов и недель */
	min-width: var(--col_width);
	max-width: var(--col_width);
}

.col_width_wide {
	/* border: 1px dashed red; */
	min-width: 9.2em;
	max-width: 9.2em;
}

.col_width_narrow {
	/* border: 1px dashed blue; */
	min-width: 8.2em !important;
	max-width: 8.2em !important;
}

.head_row.other_cols > div {
	/* border: 1px dashed red !important; */
	/* для школ надо 9.2, у них нет типов предетов и недель */
	min-width: 9.2em !important;
	max-width: 9.2em !important;
	margin: auto;
}

@media all and (max-width: 1200px) {
	/* в Main и Lenta по этой же ширине ... */
	/* Увеличиваем шрифт, соотв. .Main.width 80% -> 100% (при скрытии ленты) */
	table {
		font-size: 1.25em;
	}
}

:root {
	--bom: 1000px;
}

@media all and (max-width: 1000px) {		/*	тут начинается прокрутка таблицы по ширине	*/
	table {
		font-size: var(--table_fs);
		/*	вот эти		*/
		/* font-size: calc(0.5em + 1.1vmax); */
	}
	.Schedule {
		font-size: var(--sched_fs);		/* это тут ваще пофиг. тут только table */
		margin-top: 0;
		min-width: 1130px;
		margin-left: 0;
		margin-right: 0;
	}
	/* .col_width {
		min-width: 8em;										работают вместе
		max-width: 8em;	
	} */
}

@media all and (max-width: 375px) {
	table {
		font-size: var(--table_fs360) !important;
	}
	.Schedule {
		min-width: var(--sched_mw360)
	}
}

@media all and (max-width: 340px) {
	table {
		font-size: calc(var(--table_fs360) * 7/8) !important;
	}
	.Schedule {
		min-width: calc(var(--sched_mw360) * 7/8)
	}
}

.img_loading {
	margin: auto;
	color: var(--cl-med);
}

thead.sched {
	text-align: center;
	color: white;
	background-color: var(--cl-med);
	font-weight: var(--fw-500);
}

thead.sched > tr > .head_row {
	padding: 0.25vw 0.5vw;
}

thead.sched > tr > .no_padding {
	padding: 0;
}

.first_col {
  /* width: 100px;
  min-width: 100px;
  max-width: 100px; */

  left: 0px;
	z-index: 22;
	background-color: white;

	border: none;
	/* box-shadow: inset 1px 1px 1px -1px black; */
	box-shadow: inset -1px -1px 0px 0px var(--cl-med-dark);

	/* outline: 1px solid var(--cl-btn-back) !important;
	outline-offset: -1px; */

	/* background: linear-gradient(to right, var(--cl-med), var(--cl-med) 0%, 
										var(--cl-med-dark) 100%); */
}

.second_col {
	left: -100px !important;
}

.shift_name_cont {
	position: -webkit-sticky;
  position: sticky;
	
	top: 0px;
	z-index: 33 !important;

	font-size: 20px;
	height: 35px;

	background-color: var(--cl-fone);
	/* border-top-left-radius: 5px;
	border-top-right-radius: 5px; */

	/* border: 2px solid var(--cl-med); */

	min-width: 100%;
	max-width: 100%;

	left: 0px;
}

.shift_name {
	position: -webkit-sticky;
  position: sticky;
	
	/* height: 35px; */
	
	top: 0px;
	left: 0px;
	display: table-cell;
}

.table_divider {
	z-index: 33 !important;
	display: block;
	min-height: 1.5em;
	max-height: 1.5em;
	padding: 0;
	margin: 0;
	top: 0px;

	/* border: 1px dashed var(--cl-med); */

	background-color: var(--cl-fone);
	position: -webkit-sticky;
  position: sticky;
}

.top35 {
	top: 35px !important;
}

.head_row {
	position: -webkit-sticky;
  position: sticky;

	background-color: var(--cl-med);
	top: 0px;
	z-index: 33 !important;

	border: none;
	/* box-shadow: inset 1px 1px 1px -1px black; */
	box-shadow: inset -1px -1px 0px 0px var(--cl-med-dark);

	/* outline: 1px solid var(--cl-btn-back) !important;
	outline-offset: -1px; */

	/* background: linear-gradient(to right, var(--cl-med), var(--cl-med-dark)); */
}

.sticky_col {
	position: -webkit-sticky !important;
  position: sticky !important;

	color: white;
	background-color: var(--cl-med);

	/* border: none; */
	/* border-right: 1px solid var(--cl-med-dark); */
	/* border-bottom: 1px solid var(--cl-med) !important; */
}

.head_row.sticky_col {												/* угол */
	z-index: 44 !important;
	font-size: 1em;
	/* box-shadow: inset 0px 0px 0px 1px black !important; */

	/*	такая тень, чтобы при горизинтальной прокрутке 
			тень заголовков не торчала из под углового 			*/
	/* box-shadow: 1px 1px 0.5px 0.5px var(--cl-med-dark); */

	width: 2em !important;
	/* min-width: 3em !important; */
	max-width: 2em !important;

	cursor: pointer;

	/* box-shadow: none !important; */
	box-shadow: inset -1px -1px 0px 0px white;
	
	background-color: var(--cl-btn-back);
	color: var(--cl-btn-color);
	transition: background-color .3s linear, opacity .3s linear;

	/* outline: 1px solid var(--cl-med-dark) !important;
	outline-offset: -1px; */
}

.head_row.sticky_col:hover {
	background-color: var(--cl-med);
	/* outline: 2px solid var(--cl-med-dark) !important;
	outline-offset: -2px; */
}

.head_row.todayN {
	font-weight: var(--fw-bold);
	/* background: var(--cl-med-dark) !important; */
}

.todayN {
	background-color: var(--cl-med-dark) !important;
	box-shadow: inset -1px -1px 0px 0px var(--cl-med-dark);
	/* background: linear-gradient(to top, var(--cl-med), var(--cl-med) 0%, 
										var(--cl-med-dark) 100%); */
}

.td_center {
	text-align: center;
}

.td_time, .td_less {
	vertical-align: top;
}

.td_less {
	/* overflow: hidden; */
	padding: 2px 4px;
	
	padding: 0.2em 0.4em !important;
	padding-bottom: 0.3em !important;
	
	position: relative;
	/* transform: scale(0.99, 0.98); */
}

:root {
	--odmin : 0.5s;
}


.td_less.selected, .td_empty.selected,
.room_teacher_cont.selected {
	animation-duration: var(--odmin);
  animation-name: odmin_selected;
  animation-iteration-count: infinite;
}

.room_teacher_cont.selected_prev, .td_less.selected_prev {
	animation-duration: var(--odmin);
  animation-name: odmin_hover;
  animation-iteration-count: infinite;
}

@keyframes odmin_selected {
	from, to {
		outline: 2px dotted red;
		outline-offset: -2px;
	}
}

.td_less:hover:not(.selected), .td_empty:hover:not(.selected) {
	animation-duration: var(--odmin);
  animation-name: odmin_hover;
  animation-iteration-count: infinite;
}

@keyframes odmin_hover {
	from, to {
		outline: 2px dotted black;
		outline-offset: -2px;
	}
}


.td_time {
	font-size: 1.2em;
	line-height: 1.2;
	text-align: right;
	padding-bottom: 4px !important;
	/* min-width: 60px;
	max-width: 60px; */
	/* font-weight: 400; */
}

.time_bell {
	padding: 2px 0.2em;
}

.time_DoW {
	padding: 0.1em 0.1em;
	text-align: center;
	justify-content: baseline;
}

.time_vert {
	line-height: 1.1;
	/* transform: rotate(-90deg); */
	/* border: 1px dashed gray; */
	/* position: absolute;
	top: 2.5em;
	left: -0.2em; */
}

.time_end {
	font-size: 0.75em;
	margin-top: 0.2em;
}

.time_end_small {
	font-size: 0.55em;
	margin-top: 0.5em;
}

.time_num {
	margin-bottom: 0.15em;
}

.colsTime {
	/* display: flex; */
	/* flex-wrap: nowrap; */
	/* align-items: center; */
	/* justify-content: center; */
	font-size: 1.25em;
	padding: 0 !important;
	margin: 0 !important;
}

.time_num, .time_num_col, .time_end {
	color: var(--cl-light);
	/* border: 1px solid white;
	margin-bottom: 0.9em;
	max-width: 30px; */
}

.time_num_col {
	float: left;
	display: inline;
}

.time_time_col {
	margin-left: 1em;
	margin-right: 0.01em;
}

.time_time_col_m-r {
	margin-right: 1em;
}

.td_empty > div {
	text-align: center;
	vertical-align: top;
	/* border: 1px dashed gray; */
	padding: 0;
	margin: auto;
	padding-bottom: 0.3em;
	color: var(--cl-btn-dis-back);

	font-size: 2em;		/* шрифт увеличили, ширину уменьшили, ибо она в em */
	min-width: calc(9.2em / 2);
	max-width: calc(9.2em / 2);
}

/* .td_empty.less_today > div {
	color: white !important;
	color: var(--cl-btn-dis-color) !important;
} */

.time_curr {
	color: white;
	background-color: var(--cl-med-dark);
	box-shadow: inset -1px -1px 0px 0px var(--cl-med-dark);
	font-weight: var(--fw-500);
	/* background: linear-gradient(to top, var(--cl-med), var(--cl-med-dark)); */
}

.time_next {
	color: white;
	background-color: var(--cl-med-dark);
	box-shadow: inset -1px -1px 0px 0px var(--cl-med-dark);
	/* gray; */
	font-weight: var(--fw-500);
	/* background: linear-gradient(to top, var(--cl-med), var(--cl-med-dark)); */
}

.less_today {
	background-color: var(--cl-table-sel);
	/* box-shadow: inset 0 0 0 10em var(--cl-btn-dis-back); */
}

.typeN, .weekN {
	position: absolute;
	top: 		0px;
	right:	0px;

	min-width:	2.05em;

	text-align: center;

	font-size: 0.75em;
	padding: 0px 2px 0px 2px;
	line-height: 1.15;
	height: 1.3em;
}

.weekN {
	top: 		calc(1.4em);
	line-height: 1.25;
	/* min-width:	1.7em; */
}

.DoW_divider {
	display: block;
	min-height: 0.1em;
}

.less_weekN {
	cursor: pointer;
}

.less_anotherWeek, 
.less_anotherWeek > .room_name, 
.less_anotherWeek > .teacher_name,
.less_anotherWeek > .group_name_cont,
.less_anotherWeek > div > div > div > .room_name, 
.less_anotherWeek > div > div > .room_name, 
.less_anotherWeek > div > div > .teacher_name,
.less_anotherWeek > div > .group_name_cont,
.less_anotherWeek > div > .group_name_cont > span > span
{
	color: silver !important;	
}

.less_anotherWeek2, 
.less_anotherWeek2 > .room_name, 
.less_anotherWeek2 > .teacher_name,
.less_anotherWeek2 > .group_name_cont,
.less_anotherWeek2 > div > div > div > .room_name, 
.less_anotherWeek2 > div > div > .room_name, 
.less_anotherWeek2 > div > div > .teacher_name,
.less_anotherWeek2 > div > .group_name_cont,
.less_anotherWeek2 > div > .group_name_cont > span > span
{
	color: silver !important;	
}

.less_anotherWeek > .typeN,
.less_anotherWeek > .weekN,
.less_anotherWeek > div > .typeN,
.less_anotherWeek > div > .weekN 
{
	background-color: silver !important;
}

/* .less_anotherWeek2 > .typeN,
.less_anotherWeek2 > .weekN,
.less_anotherWeek2 > div > .typeN,
.less_anotherWeek2 > div > .weekN 
{
	background-color: aqua !important;
} */

.less_curr {
	/* box-shadow: inset 0 0 0 10em var(--cl-light); */
	/* border: 2px solid var(--cl-med); */

	outline: 2px solid var(--cl-med-dark);
	 /* !important; */
	outline-offset: -1px;
	/* background-color: var(--cl-fone); */
	background-color: white;
	box-shadow: inset 4px 4px 8px 0px rgba(0,0,0,0.5);

	left: 0px;
	z-index: 5;
}

.less_next {
	/* border: 2px solid var(--cl-med-dark) !important; */
	outline: 2px solid var(--cl-med-dark);
	 /* !important; */
	outline-offset: -1px;
	background-color: var(--cl-table-sel);

	/* иначе outline 2px нижняя линия перекрывается на 1px */
	z-index: 5;
}

.large {
	/* transform: perspective(100px) rotateX(5deg); */
	transform: scale(1.1);
	box-shadow: inset 0px 0px 0px 2px var(--cl-med),
							10px 10px 10px 0px rgba(0, 0, 0, 0.5);
}

.subj_name, .room_name {
	line-height: 1.2;
	
	/* border: 1px dashed silver; */
}

.subj_name {
	/* border: 1px dashed silver; */
	font-weight: var(--fw-500);
	margin-bottom: 0.3em;
	/* margin-right: 1.5em; */
	/* min-height: max(var(--subj-name-h), 1.5em); */
	min-height: var(--subj-name-h);

	/* position: relative; */
}

.less_today > .subj_name,
.less_today > div > .subj_name {
	font-weight: var(--fw-bold);
}

.subj_name.same {
	/* color: blue !important; */
	/* text-decoration: underline; */
	/* font-weight: var(--fw-bold) !important; */
	color: white;
	/* var(--cl-med-light); */
	/* padding-left: 2px; */
	background-color: var(--cl-med-dark);
	/* z-index: 111 !important; */
}

.col_width_narrow > .subj_name.same:after {
	margin-right: 1.4em;
}

:root {
	--subj-cnt : '0';
}

.subj_name.same:after {
	line-height: 1.12;
	outline: 1px solid var(--cl-med-dark) !important;

	content: var(--subj-cnt);
	color: var(--cl-med-dark);
	background-color: white;
  position: absolute;
  top: .25em;
  right: 0.35em;
	padding: 0 2px 0 2px;
}


.subj_name.diffSubjects {
	color: red;
}

.room_name_cont {
	min-width: 9.2em !important;
	max-width: 9.2em !important;
	position: relative;
	/* border: 1px dotted silver; */

	/* display: table; */
	/* display: contents; */
	margin: 0 !important;
	padding: 0 !important;
}

.room_name {
	/* border: 1px dotted blue !important; */
	color: blueviolet;
	font-size: 0.9em;
	/* margin-bottom: 0.1em; */

	/* min-width: 10.5em !important;
	max-width: 10.5em !important; */
	/* width: auto; */
	display: table-cell;
	line-height: 1.0;
	padding: 2px 0px !important;
}

.subN {
	/* float: right; */
	font-size: 0.8em;
	
	position: absolute;
	right: -0.25em;
	bottom: 0.1em;
	/* top: 0.2em; */

	border: 1px solid silver;
	color: gray;
	line-height: 1.3;
	background-color: var(--cl-light);
	padding: 0 4px 2px 4px;
	min-width: 2.5em;
	text-align: center;
}

.subN.selected {
	color: white;
	background-color: red;
}

.subN.selected_prev {
	color: white;
	background-color: black;
}

.room_name > span {
	display: none;
	min-width: 15em !important;
	max-width: 15em !important;
	line-height: 1.2;
}

hr {
	background-color: white !important;
	padding: 0px !important;
	margin: 0.2em 0 !important;
	/* min-width: 9.4em;
	max-width: 9.4em; */
}

.room_name:hover > span {
	border: 1px dotted silver;
	padding: .3em .5em;
	/* display: block; */
	display: inline-block;
	color: black;
	background:  #ffffff !important;
	z-index: 100 !important;
	left: 0px;
	margin: 0px;
	width: auto;
	position: absolute;
	top: calc(1.5em);
	/* var(--subj-name-h) +  */
	left: 0.5em;
	text-decoration: none;
	box-shadow: 4px 4px 4px rgba(0,0,0,.5);
}

.room_name:hover > span > span {
	color: gray;
}

.teacher_name {
	color: mediumslateblue;
	font-size: 0.9em;
	white-space: nowrap;

	/* border: 2px dotted silver; */

	/* min-width: 10.5em !important;
	max-width: 10.5em !important; */
	/* чтобы ширина не на всю ячйку была */
	/* иначе хочешь клик по ячейке, получаешь по преподу. */
	display: table-cell;

	/* position: absolute;
	bottom: 0.3em; */
}

.no_teacher {
	color: red;
}

.group_name_cont {
	/* border: 1px dashed red; */
	position: relative;
	min-width: 9.5em !important;
	max-width: 9.5em !important;
}

.group_name_cont .subN {
	right: 0px;
}

.group_name {
	cursor: pointer;
	color: var(--cl-med);
}

.room_teacher_cont {
	/*	в нём кабинет, препод и номер подгруппы.
			чтобы, если кабинеты у подгрупп одинаковые (ИМиП), 
			показывать его только у 1й подгруппы, чтобы место не занимать 2й раз */
	min-width: 9.2em !important;
	max-width: 9.2em !important;
	position: relative;
	/* border: 1px dashed green; */
}


/* th.head_row,  */
td.sched {
	/* margin: 10px !important; */
	/* border: 1px solid var(--cl-med); */

	/* box-shadow : inset 0px 0px 0px 1px var(--cl-med); */

														/* ccc */
	border-left: 0.5px solid #ccc;
	border-top: 0.5px solid #ccc;
	/* border-left: 0.5px solid var(--cl-med);
	border-top: 0.5px solid var(--cl-med); */

	/* box-shadow: 0px 0px 1px 0px var(--cl-med-dark); */
}

.remote, .remote15 {
	background-image: url('../images/icon_remote_25.png');
	background-position: bottom 4px right 1px;
	background-repeat: no-repeat;
}

.remote15 {
	background-size: 12%;
}