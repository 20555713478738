.enter {
	font-size: 0.5em;
	/* width: min(max(500px, 50vw), 600px); */
	min-width: max(30vw, 30em);
	height: min(70vh, 19em);
	/* min-width: 50vw !important; */
	background-color: var(--cl-med);
}

/* .enter_sprav {
} */

.enter_header_span, .enter_header_span_L {
	/* background-color: var(--cl-med-dark); */
	/* border: 2px dashed var(--cl-med-dark); */
	font-size: 1.25em;
	text-shadow: 0px 0px 2px var(--cl-med-dark);
	padding: 0 .3em 0em 0.3em;
	
	/* line-height: 1.4; если не менять шрифт */
	line-height: 1;
	margin-top: 0.1em;
	
	float: right;
}

.enter_header_span {
	margin-left: 0.35em;
}

.enter_header_span_L {
	float: left;
	margin-right: 0.35em;
}

.enter_body {
	align-self: baseline;
	/* font-size: 0.75em; */
	/* border: 2px dashed green; */
	height: calc(100% - 2em - 2em - 1px);
	/* width: 100%;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%; */
	background-color: white;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.enter_header_left {
	/* text-align: left; */
	padding-top: 0.25em;
	/* padding-left: 0.5em; */
	overflow: hidden;
}

.enter_item {
	/* border: 1px dashed red; */
	padding: 0.5em 1em 0 1em;
	position: relative;
	display: flex;
	overflow: hidden;
	min-height: 2.2em;
}

.enter_item_label {
	/* float: right; */
	/* border: 1px dashed silver; */
	width: 35%;

	text-align: right;
	margin-right: 1em;
	/* display: inline-block; */
	float: left;

	line-height: 1;
	margin: auto 1em auto 0;

	background-position: bottom 0.35em left 1px;
}

.text_label {
	/* border: 1px dashed gray; */
	line-height: 1;
	margin: auto 0 auto 0;
}

.lh1 {
	line-height: 1;
	padding-bottom: 1px;
	margin-top: -3px;
}

input[type="checkbox"] { /* change "blue" browser chrome to yellow */
	filter: hue-rotate(calc(max(10deg, var(--hue-rotate-fone)) - 20deg));
  /* filter: invert(100%) hue-rotate(80deg) brightness(1); */
}

.l_up {
	margin-top: 5px;
	/* margin-top: -3px; */
	/* margin-bottom: -6px; */
}

.l_up1 {
	margin-top: 3px;
	/* margin-top: -3px; */
	/* margin-bottom: -6px; */
}

label.required, span.required {
	text-decoration: underline;
	/* color: red; */
}

label._id, span._id {
	font-style: italic;
	/* color: red; */
}

/* .enter_item_label.sprav {
	width: 45%;
} */

.enter_item_field.sprav {
	/* width: 50%; */
	resize: none;
}

/* .enter_item_field.sprav {
	width: 50%;
} */

.enter_item_field {
	border-radius: 2.5px;
	border-color: var(--cl-med-dark);
	/* border: 1px dashed green; */
	float: left;
	width: 60%;
	/* display: inline-block; */
	text-align: left;

	height: 2;
	
	background-position: bottom 0.3em left 1em;
}

input.required, select.required, textarea.required {
	outline-color: red !important;
	border-color: red;
}

.enter_item_field.required {
	border-color: red;
}

.checkbox {
	/* border: 1px dashed green;
	background-color: tomato; */
	width: 1.5em;
	height: 1.5em;
	/* margin-top: 0.1em; */
}

.width_30 {
	width: 30% !important;
}

select, input, textarea {
	outline-color: var(--cl-med-dark) !important;
	background-color: var(--cl-light) !important;
	border-width: 1px;
}

textarea.text {
	font-size: 0.75em !important;
}

textarea.code {
	font-family: monospace;
}

select, input {
	max-height: 1.7em;
}

.enter_footer {
	position: absolute;
	/* background-color: var(--cl-fone); */
	/* background-color: var(--cl-dark); */
	height: 2em;
	padding-top: 0.15em;
	padding-right: 0.5em;
  bottom: 0;
  left: 0;
	width: 100%;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	overflow: hidden;
	overflow-wrap: nowrap;
}

.enter_btn_left {
	float: left;
}

.enter_btn_right {
	float: right;
}