.Teacher_Cont_Cont {
	/* border: 1px dashed red; */
	margin: auto;
	margin-left: .01em;
	margin-right: .01em;
	margin-top: .01em;
	
	/* width: 90%; */

	display: flex;
	position: relative;
	/* align-items: center; */
	/* justify-content: center; */
	overflow: auto;
	/* background-color: var(--cl-table-back); */
	/* background-color: rgba(255,255,255,.5); */

	height: 100%;
}

.Teacher_Cont {
	/* border: 1px dashed green; */
	margin: 1em 0em 0 1.5em;

	/* max-width: 90%; */
	/* width: 100%; */
	margin-top: 2%;
	margin-bottom: 2%;
	/* height: 90%; */

	flex-direction: column;
	/* overflow: auto; */

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	line-height: calc(2.5em - 1vw);
}


@media all and (max-width: 1300px) {
	.Teacher_Cont {
		font-size: 0.9em;
		/* margin-left: 0; */
		margin-right: 0;
	}
}

/* @media all and (max-width: 1000px) {
	.Teacher_Cont {
		font-size: 0.75em;
		margin-right: 0;
	}
} */

@media all and (max-width: 720px) {
	.Teacher_Cont_Cont {
		/* border: 1px dashed red; */
		margin-top: 0em;
		margin-left: 0em;
		margin-right: 0.5em;
	}
	.Teacher_Cont {
		/* margin: 0em 0em; */
		font-size: 0.65em;
		/* margin-left: 0; */
		margin-right: 0;
	}
}

@media all and (max-width: 520px) {
	.Teacher_Cont_Cont {
		/* border: 1px dashed red; */
		/* margin-left: 0.5em; */
		margin-right: 0em;
	}
	.Teacher_Cont {
		font-size: 0.5em;
		/* margin-left: 0;
		margin-right: 0; */
	}
}

.teacher {
	position: relative;
	/* border: 1px dashed silver; */
	width: 11em;
	white-space: nowrap;
	text-align: left;
	cursor: pointer;
}

.teacher:hover {
	color: var(--cl-med);
}

.teacher:before {
	font-weight: 500;

	font-size: var(--symbol-size);
	margin-top: var(--symbol-top);
	margin-left: var(--symbol-left);

	content: var(--link-symbol);
	color: var(--cl-med);
  position: absolute;
  top: 0;
	/* left: calc(-0.3em - 2vw); */
	/* top: calc(-0.1em); */
	top: 0;
	left: calc(-0.7em);

	line-height: calc(2.2em - 0.75vw - var(--symbol-lh));
	/* line-height: calc(2.5em - 1.5vw); */

	visibility: hidden;
	opacity: 0;
	transition: visibility .3s linear, opacity .3s linear;
}

.teacher:hover:before {
	visibility: visible;
	opacity: 1;
	transition: visibility .0s linear, opacity .0s linear;
}

.teacher.curr:before {
	visibility: visible;
	opacity: 1;
	transition: opacity .0s linear;
}


.teacher.other_letter {
	font-weight: 500;
}

.teacher_new_letter {
	/* background-color: var(--cl-med);
	padding: 0 0.2em;
	color: white; */
	color: var(--cl-med);
	font-weight: 500;
	font-size: 1.25em;
	margin-left: -0.05em;
	line-height: 1;
	/* border-bottom: .1em solid var(--cl-med); */

	text-align: left;
}

.teacher.new_letter {
	text-decoration: underline;
	text-decoration-color: var(--cl-med);
	/* background: var(--cl-light); */
	/* border-left: 5px solid var(--cl-med);
	box-shadow: -18px 0 0 -8px var(--cl-med);
	border-spacing: -5px; */
}

.Keys_Cont {
	border-top: 1px solid var(--cl-med);
	position: relative;
	/* width: 100%; */
	overflow-x: auto;
	margin: auto;
	min-height: calc(6em + 2em);
	/* height: auto; */
	/* border: 1px dashed silver; */
	margin: .25em;
	padding-top: 0.3em;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.Button_Filter.KeySuka {
	margin: 	4px;
}

@media all and (max-width: 720px) {
	.Button_Filter.KeySuka {
		/* font-size: 0.5em !important; */
		/* вот это из Select.css @1000px: */
		font-size: calc(var(--fs) * 4/5);
		line-height: calc(-4px + var(--bh));
		--bh: calc(var(--hh) * 0.55);
		min-height: var(--bh);
		max-height: var(--bh);

		width: 			40px !important;
		min-width:	40px !important;
		max-width:	40px !important;
		/* padding: 		0 !important; */
		margin: 		2px;
	}

	.Keys_Cont {
		min-height: calc(5.2em + 1.6em);
	}
	
		/* .Key_Row {
		align-self: flex-start !important;
		margin: 0;
	} */
}

@media all and (max-width: 580px) {
	.Button_Filter.KeySuka {
		font-size: 0.5em !important;
		width: 			30px !important;
		min-width:	30px !important;
		max-width:	30px !important;
		/* padding: 		0 !important; */
		margin: 		2px !important;
		box-shadow: none;
		border-radius: 5px;
	}
	
	.Keys_Cont {
		min-height: calc(5.2em + 1.6em);
	}
}


@media all and (max-width: 430px) {
	.Button_Filter.KeySuka {
		font-size: 0.5em !important;
		width: 			25px !important;
		min-width:	25px !important;
		max-width:	25px !important;
		min-height:	32px !important;
		max-height:	32px !important;
		line-height: 2;
		/* padding: 		0 !important; */
		margin: 		1.5px !important;
		box-shadow: none;
		border-radius: 5px;
	}
	
	.Keys_Cont {
		min-height: calc(4em + 1em);
	}
}

@media all and (max-width: 350px) {
	.Button_Filter.KeySuka {
		font-size: 0.5em !important;
		/* width: 			22px !important; */
		min-width:	22px !important;
		max-width:	22px !important;
		min-height:	32px !important;
		max-height:	32px !important;
		line-height: 2;
		/* padding: 		0 !important; */
		margin: 		1.5px !important;
		box-shadow: none;
		border-radius: 3px;
	}
	
	.Keys_Cont {
		min-height: calc(4em + 1em);
	}
}

		/* .Key_Row {
		align-self: flex-start !important;
		margin: 0;
	} */

.Key_Row {
	float: unset;
	display: flex;
	align-self: center;
	flex-wrap: nowrap;
}