.odmin_Page {
	display: flex;
	flex-grow: 1;
	background-color: var(--cl-fone);
	/* height: 324; */
	z-index: 111;
}

.odmin_Tabs {
	border-right: 1px solid #eee;
	/* min-width: 100px;
	max-width: 100px; */
	padding: 0 !important;
	margin: 0 10px;
	align-content: flex-start;
	vertical-align: baseline;
	white-space: nowrap;
}

.odmin_TabsCont {
	display: flex;
	flex-flow: column;
	/* width: 200px; */
}

.odmin_TabsHeader {
	/* border-right: 1px solid #eee; */
	/* border-bottom: 1px solid #eee; */
	/* background-color: var(--cl-light); */
	font-size: 22px;
	text-align: left;
	padding-left: 10px;
	height: 10px;
}

.TableSortLabel {
	color: white !important;
}

.MuiTab-wrapper {
	/* background-color: thistle; */
	align-items: flex-end !important;
	max-width: 120px;
}

.Mui-selected {
	font-weight: bold !important;
	background-color: var(--cl-light) !important;
}

.Mui-active {
	background-color: var(--cl-light) !important;
}

.tab_divider {
	background-color: var(--cl-med) !important;
	margin: 4px !important;
	padding: 0px !important;
	min-height: 1px !important;
	max-height: 1px !important;
	height: 1px !important;
}

.TabPanel {
	width: 90%;
	text-align: left;
	/* background-color: var(--cl-light); */
}

.TabPanelHeader {
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
}

.TabPanelFooter {
	margin-top: 0px !important;
	padding-top: 0px !important;
}

.PaperCont {
	width: 100%;
	outline-width: 0px !important;
	outline-color: var(--cl-light);
	/* background-color: #eee !important; */
	/* padding: 12px */
	/* max-height: calc(50%); */
}

.DataTableCont {
	max-height: calc(100vh - 380px);
	background-color: white;

	/* position: relative;
	overflow: hidden; */
}

.DataTable {
	position: relative;
	/* overflow: hidden; */
}

.TableComment {
	font-size: 0.6em;
	display: block;
	color: red;
	text-align: right;
	/* position: relative; */
	/* font-size: 18px; */
	/* padding: 10px 0px 0px; */
}

.DataTableHeader {
	color: white !important;
	background-color: var(--cl-med) !important;
}

.DataTableCell {
	border-bottom: none !important;
	border-left: 1px solid #eee !important;
	vertical-align: top !important;

	display: inline-block;
 	position:relative;
	 /* overflow: hidden; */
}

.row_image {
	/* width : 22.5%; */
	/* height: 3em; */

	position: absolute;

	/* top: -2.75em; */
	right: 16px;

	/* margin-top: 1.55em; */
	/* margin-right: .86em; */
	/* display: table-caption; */

	border: 1px solid silver;
	z-index: 111;
}

.DataTableCell.selected {
	background-color: var(--cl-light);
}

.DataTableCell.current_uz {
	/* color: blue; */
	color: var(--cl-med);
}

.DataTableCell:first-child {
	border-left: none;
}

.DataTableCell.selected:first-child:before {
	content:"\A";
	border-style: solid;
	border-width: 10px 2px 22px 2px;
	border-color: var(--cl-med);
	position: absolute;
	left: -1px;
	top: 0px;
}

.table_buttons_cont {
	/* display: inline-block; */
	position: relative;
	margin-top: 1em;
	font-size: 0.65em;
	/* background-color: red; */
}

.op_disabled {
	color: silver !important;
	border-color: gray;
	cursor: default !important;
}

.op_disabled:hover {
	background-color: var(--cl-light);
}

