.Events_Cont {
	text-align: left;
	line-height: 1;
	/* padding: 1em; */
	display: flex;
	flex-wrap: wrap;
	/* flex-direction: row; */
	font-size: max(1.25vw, 16px);
	padding-bottom: 1em;
}

.Event_Header {
	padding: 0.4em 0.6em;
	border-top-left-radius: 2.5px;
	border-top-right-radius: 2.5px;
	border-bottom: 1px solid var(--cl-med-light);
}

.Event_Body {
	padding: 0.4em 0.6em 0.6em;
	background-color: white;
	height: 100%;
	color: black;
}

.Event {
	color: black;
	background-color: var(--cl-med-light);
	transition: color .3s linear, background-color .3s linear;
	
	border-radius: 2.5px;
	display: block;
	position: relative;
	min-width: 200px;
	/* max-width: 350px;
	max-height: 350px; */
	overflow: hidden;
	max-width: 22%;
	max-height: 350px;

	/* max-width: 20%; */
	flex-grow: 1;
	margin-left: 1em;
	margin-top: 1em;
	border: 1px solid var(--cl-med-light);
	box-shadow: 0.25em 0.25em 0.25em rgba(0,0,0,0.2);
	align-content: center;
	cursor: pointer;
}

@media all and (max-width: 1000px) {
	.Event {
		max-width: 30%;
	}
}

@media all and (max-width: 700px) {
	.Event {
		max-width: 44%;
	}
}

@media all and (max-width: 400px) {
	.Event {
		max-width: 88%;
	}
}

.Event:hover {
	/* background-color: var(--cl-header-back);
	color: var(--cl-header-color); */
	background-color: var(--cl-med);
	color: var(--cl-light);
}

.Event img {
	max-width: 100%;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

