:root {
	--dashW:				0px;
	--buttonW:			130px;

	--link-symbol:	'❯';
	--symbol-size:	unset;
	--symbol-top:		unset;
	--symbol-left:	unset;

	--filter_mb:		1vh;
}

.ErrMsg {
	color: red;
}

.debug {
	position: absolute;
	left: 1px;
	top: 0px;
	line-height: 1;
	font-size: max(1vmax, 0.3em);
	/* font-size: 0.3em; */
	color: var(--cl-header-color);
}

.Select_cont {
	/* background-image: url('../images/fone_select.png');
	background-position: top;
	background-repeat: no-repeat;
	background-size: 100% auto; */

	height: calc(100vh - var(--footer-height));
  /* width: calc(100% - var(--lentaWidth)); */

	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
}

.Select {
	/* border: 2px dashed red; */
	position: relative;
	/* min-height: 100vh; */
	/* background-color: var(--cl-fone); */
	font-size: calc(var(--fs));
	/* margin: calc(var(--hh)/5); */

	flex-grow: 1;

	display: flex;
	align-items: baseline;
	justify-content: baseline;
	flex-direction: column;

	overflow: auto;
}

/* .fone_select {
	background-image: url('../images/fone_select.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% auto;
}  */


/* .Select > .Fac_Cont {
	overflow: hidden;
} */

.Filter_Cont, .Filter_Cont_School {
	position: relative;
	/* margin: calc(var(--hh)/5); */
	/* margin-top: calc(var(--hh)/3); */
	/* border-top: 1px solid var(--cl-med); */

	/* margin-top: -5px; */

	/* margin-bottom: 1vh; */
	/* НАДО В ЗАВИС. ОТ ВЫСОТЫ. */

	border-top: 1px solid var(--cl-med);
	margin-top: 0.5em;

	margin-bottom: var(--filter_mb);
	margin-right: calc(var(--nav-width) * 2 + 30px + var(--FilterMargin));

	/* margin-bottom: 10px; */
	/* width: 100%; 											!!! */
	/* display: flex; */
	overflow: hidden;
}

.no_border_top {
	/* border-top: none; */
	margin-top: 0.5em;
	/* margin-top: 0;	 */
}

@media all and (max-width: 1000px) {
	/* кнопка "Назад" исчезла - уменьшаем отступ */
	.Filter_Cont, .Filter_Cont_School {
		margin-right: calc(var(--nav-width) * 1 + 10px + var(--FilterMargin));
	}
	/* .FK_vuz {
		margin-right: 80px;
	} */
}

/* .Filter_Cont_School {
	border: 1px dashed red;
} */

@media all and (max-width: 800px) {
	/* чтобы кнопки курсов школ начали заранее переноситься,
			чтобы на них не наехала кнопка "Домой" */
	/* .Filter_Cont_School {
		width: calc(100% - 30px);
	} */
}
@media all and (max-width: 380px) {
	/* 500 */
	/* здесь несоклько кнопок курсов уже перенеслись на 2 строку
		 и кнопка "Домой" оказывается под 1й строкой */
	.Filter_Cont_School {
		width: 100%;
	}
}

@media all and (min-height: 640px) {
	/* .Filter_Cont, .Filter_Cont_School {
		margin-bottom: 1vh;
	} */
}

.Filter_Level, .Filter_Kurs {
	float: right;
	display: flex;

	/* margin-right: 40px; */
	/* именно здесь 10px, а у _Cont -10px */
	/* чтобы когда кнопки 2 в строки - был промежуток*/
	margin-top: 0px;
	/* border: var(--dashW) dashed red; */
}

.Filter_Kurs {
	/* Кнопки курсов (особенно школьных, их много) переносятся при уменьшении длины */
	flex-wrap: wrap;
	/* margin-top: 1vh; */
}

/* .Filter_Cont {
	border: 1px solid red;
	padding-right: 150px;
} */

/* отступ для кнопок навигации */
/* .FK_vuz {
	margin-right: 150px;
} */

.Filter_Cont_School {
	flex-wrap: wrap;
	/* margin: 0 10px; !!!!!!!!! не включать */
}

.Filter_Cont_School .Filter_Kurs .Button_Filter {
	max-width: 60px;	
	/* чтобы школьные кнопки курсов, переносясь, не растопыривались на 100% */
}

.Filter_Level {
	float: left;
	margin-right: 0px;
	/* margin-left: 40px; */
}

/* @media all and (max-width: 900px) { */
	/* 719 а не 720, чтобы в мобильном виде "альбомном" (716 для Galaxy Fold ёба :)
		было больше места по вертикали, хотя всёравно его там пиздецки мало */
	.Filter_Kurs.FK_many {
		/* Кнопки курсов заранее прыгают под кнопки уровней, чтобы блоком не перенеслись */
		/* , .Filter_Level.FK_many */
		width: 100%;
		/* border: 1px dashed red; */
	}
	.Filter_Cont.FK_many {
		margin-right: unset;
	}
	/* .FK_vuz {
		margin-right: unset;
	} */
/* } */

@media all and (max-width: 720px) {
	.Button_Cont {
		width:100% !important;
	}

	/* кнопки групп... */
	.Button {
		margin: 1vh 2vw 1vh 2vw !important; 
	}
}

@media all and (max-width: 1100px) {
	/* отступы - промужетки между кнопками фильтра */
	.Filter_Kurs .Button_Filter {
		margin-right: 5px !important;
	}	
	.Filter_Level .Button_Filter {
		margin-left: 5px !important;
	}	
}

.Filter_Kurs > :last-child > .Button_Filter {
	margin-right: 0px !important;
}
.Filter_Level > :first-child > .Button_Filter {
	margin-left: 0px !important;
}

/* .Filter_Kurs > div > div:last-child {
	margin-right: 0px !important;
}
.Filter_Level > div > div:first-child {
	margin-left: 0px !important;
} */

:root {
	--FilterMargin: calc(1vw + 2px);
	/* --FilterMargin: .5em; */
	/* --FilterMargin: calc(var(--hh)/5); */
}

#Filter_Cont {
	margin-left: var(--FilterMargin);
	margin-right: var(--FilterMargin);
}

#Filter_Cont_School, #Filter_Cont_School_Bottom {
	/* border: 1px dashed red; */

	margin-left: auto;
	margin-right: auto;

	min-height: unset;

	display: flex;
	align-items: center;
}

@media all and (max-width: 500px) {
	#Filter_Cont_School {
		margin-left: var(--FilterMargin);
		margin-right: unset;
	}
}

.Chain, .Chain_School {
	/* background-color: rgba(255,255,255,.75);
	border-radius: 0.1em;
	padding: 0.05em 0.25em 0.2em; */
	text-shadow: 0 0 10px var(--cl-fone);

	font-size: calc(0.5em + 2vw);

	line-height: 1.0;
	margin-top:		 calc(1vw - 0.2vw + 2px);
	margin-bottom: calc(1.2vw - 0.2vw + 2px);
	text-align: left;
	float: left;
}

@media all and (max-width: 920px) {
	#Filter_Cont_School_Bottom {
		/* отступы от краёв, когда кнопки курсов начинают переноситься */
		/* это особенно для мобильного вида */
		padding: 0 1vw 0 2vw;
		/* padding: 0 2vw 0 4vw; */
	}
}

.Chain_School {
	/* margin-left: 10px; */
	text-align: center;
}

.Chain_Link {
	/* border: 1px dashed red; */
	position: relative;
	margin-left: calc(0.3em + 1.75vmax);
	margin-right: calc(0.15em + 1vw);
	font-weight: 500;

	color: black;
	/* border: 1px dashed red; */

	display: inline-block;
	cursor: pointer;
	/* margin-top: 1vw; */
	/* margin-bottom: 1vmin; */

	align-items: center;
	
}

.Chain_Dop_Cont {
	/* border: 1px dashed red; */
	float: right;
	width: auto;
	text-shadow: 0 0 10px var(--cl-fone);
	/* display: inline-block; */
}

.Chain_Dop {
	/* float: right;
	margin-left: calc(0.3em + 1.75vmax); */
	text-shadow: 0 0 10px var(--cl-fone);
	font-weight: 500;

	margin-top: 1vw;
	margin-left: 2vw;
	margin-bottom: 1.2vw;
	vertical-align: bottom;
	
	float: right;
	line-height: 1.0;

	font-size: calc((0.5em + 2vw));

	/* border: 1px dashed red; */
	max-width: 20em;
	text-align: right;

	/* margin-top: 0;
	margin-bottom: 1.2vw;
	float: left;
	max-width: unset;
	text-align: left; */
}

.Chain_spec, .Chain_shiftN {
	margin-top: 1vw;
	margin-bottom: 1.2vw;
	vertical-align: bottom;
	
	float: right;
	line-height: 1.0;

	font-size: calc((0.5em + 2vw) * 0.5);

	/* border: 1px dashed red; */
	max-width: 20em;
	text-align: right;
}

/* .Chain_shiftN {
	float: left;
} */

@media all and (max-width: 716px) {
	/* 719 а не 720, чтобы в мобильном виде "альбомном" (716 для Galaxy Fold ёба :)
		было больше места по вертикали, хотя всёравно его там пиздецки мало */
	.Chain {
		float: unset;
	}
	.Chain_spec {
		margin-top: 0;
		margin-bottom: 1.2vw;
		float: left;
		max-width: unset;
		text-align: left;
		/* margin: 0; */
	}
}


.Chain_Link:hover {
	color: var(--cl-med-dark);
	/* text-shadow: none; */
}

.Chain_Link:before {
	font-size: var(--symbol-size);
	margin-top: var(--symbol-top);
	margin-left: var(--symbol-left);

	content: var(--link-symbol);
	color: var(--cl-med-dark);
  position: absolute;
  top: 0;
	/* left: calc(-0.3em - 2vw); */
	left: calc(-0.3em - 1.75vmax);
}

.Fac_Cont {
	text-align: left;

	/* border: 1px dashed silver; */
	font-size: calc(3.5vmin);
	/* calc(var(--fs) * 12vmax); */

	/* так он остаётся по центру                       */
	/* но т.к. .Select{align-items: baseline;} -       */
	/* то прокрутка работает нормально                 */
	/* с align-items: center - не докручивает до верха */
	margin: auto;
	/* margin-left: 2em; */
	/* height: 100%; */
	/* margin-top: 0;
	margin-bottom: 0; */

	/* display: flex;
	flex-direction: column;*/
	position: relative;
	/* padding-top: 30px;
	border-top: 1px solid var(--cl-med); */
}

.Fac {
	/* border: 1px dashed silver; */
	cursor: pointer;
	position: relative;
	margin-bottom: calc((5vh) / 2);
	line-height: 1;
}

.Fac:hover {
	color: var(--cl-med);
	/* border-bottom: 1px solid var(--cl-med) !important; */
	/* text-decoration-color: var(--cl-med) !important;
	text-decoration: underline; */
}

.Fac:before {
	font-weight: var(--fw-bold);

	/* line-height: 1.1; */
	font-size: var(--symbol-size);
	margin-top: var(--symbol-top);
	margin-left: var(--symbol-left);
	/* ❯ ⋆ */
	/* для юникодовой звезды: */
	/* "symbol-top"     : "-0.32em",
	"symbol-left"    : "-0.12em" */

  content: var(--link-symbol);
	color: var(--cl-med);
  position: absolute;
  top: 0;

	/* left: calc(-3.5vmin); */
	left: -0.75em;

	visibility: hidden;
	opacity: 0;
	transition: visibility .3s linear, opacity .3s linear;;
}

.Fac:hover:before {
	visibility: visible;
	opacity: 1;
	transition: visibility .0s linear, opacity .0s linear;;
}

.Fac.curr:before {
	visibility: visible;
	opacity: 1;
	transition: opacity .0s linear;
}

.Button_Cont {
	/* border: var(--dashW) dashed green; */
	
	/* overflow: hidden; */
	margin: auto;
	width: 85%;

	/* gap: 2rem; */
	/* padding: 20px; */

	display: flex;
	flex-wrap: wrap;
	/* align-items: center; */
	justify-content: center;
	/* baseline; */
	/* space-evenly */
	/* flex-flow: wrap */
	/* align-content: center; */
}

.Button:hover, .Button_Filter:hover {
	/* color: var(--cl-med);
	background-color: var(--cl-dark); */
	color: white;
	background-color: var(--cl-med);
	/* opacity: 1; */
}

.Button.selected, .Button_Filter.selected {
	/* color: white;
	background-color: var(--cl-dark); */
	color: white;
	background-color: var(--cl-med-dark);
}

.Button.disabled, .Button_Filter.disabled {
	color: var(--cl-btn-dis-color);
	background-color: var(--cl-btn-dis-back);
	box-shadow: none;
	border: 1px solid var(--cl-btn-dis-color);
	cursor: default;
}


/* .Button_Cont .Button:hover:not(.selected) {
	animation-duration: var(--odmin);
  animation-name: odmin_hover_button;
  animation-iteration-count: infinite;
} */

@keyframes odmin_hover_button {
	from, to {
		outline: 2px dotted black;
		outline-offset: 4px;
	}
}


.Button, .Button_Filter {
	/* opacity: 1; */

	transition: color .3s linear, background-color .3s linear;

	flex-grow: 1;
	/* flex-shrink: 1; */

	/* background-color: var(--cl-med);
	color: white; */
	background-color: var(--cl-btn-back);
	color: var(--cl-btn-color);

	border-radius: 10px;
	border: 1px solid var(--cl-dark);
	box-shadow: inset 0px 0px 1px 2px var(--cl-fone);
	/* box-shadow: inset 0px 0px 1px 2px var(--cl-light); */
	
	/* white-space: nowrap; */
	
	margin: calc(0.0em + 1.2vh) 20px; 
	/* см. gap: 2rem; */

	/* flex: 1 1 calc((100% / 3) - 2rem); */

	/* padding: 0 10px; */
	/* min-width: calc(var(--hh)*4/2); */
	/* max-width: calc(var(--hh)*14/2); */
	/* min-width: calc(var(--hh)*2/2); */
	/* max-width: 50%; */

	/* --bh: calc(8vh); */
	/* --bh: calc(var(--hh) * 3/4); */
	--bh: calc(var(--hh) * 0.60);
	min-height: var(--bh);
	max-height: var(--bh);
	line-height: calc(var(--bh) - 4px);

	cursor: pointer;
}


@media all and (max-width: 375px) {
	.Button, .Button_Filter {
		--bh: calc(var(--hh) * 0.60);
		line-height: calc(var(--bh) - 2px);
	}
}

.Button {
	min-width: var(--buttonW);
	max-width: var(--buttonW);

	flex-basis: 200px;
	position: relative;
	/* flex: auto; */
}

.Button_Filter {
	/* min-width: 60px; */
	margin: 0;
	margin-right: 10px;
	padding: 0 10px;
}

@media all and (max-width: 1000px) {
	/* это было для небольшого уменьшения кнопок клавы по высоте, но не её самой */
	/* .Button_Filter {
		font-size: calc(var(--fs) * 4/5);

		--bh: calc(var(--hh) * 0.55);
		min-height: var(--bh);
		max-height: var(--bh);
		line-height: calc(-4px + var(--bh));
	} */
}

/* кнопки классов (нет уровней) НЕ растягиваются в ширину */
.Filter_Cont_School .Filter_Kurs .Button_Filter {
	margin-top: 1vh;
	padding: 0 0px;
	min-width: 50px !important;
	max-width: 50px !important;
}

/* кнопки курсов (когда есть уровни) - растягиваются */
.Filter_Kurs .Button_Filter {
	margin-top: 1vh;
	padding: 0 0px;
	min-width: 50px !important;
	/* max-width: 50px !important; */
}

.Filter_Level .Button_Filter {
	padding: 0 15px !important;
	min-width: 60px;
	margin-top: 1vh;
	margin-right: 0px !important;
	margin-left: 10px;
}

@media all and (max-width: 400px) {
	/* ещё ужимаем кноки уровней и курсов 
		 оставим так. зато если что - влезет ещё уровень и 6 курсов
		 а в школах - 12 классов например.
	*/
	.Filter_Kurs .Button_Filter {
		min-width: 45px !important;
		max-width: 45px !important;
	}
	.Filter_Cont_School .Filter_Kurs .Button_Filter {
		min-width: 45px !important;
		max-width: 45px !important;
	}
	.Filter_Level .Button_Filter {
		padding: 0px 10px !important;
	}
}

